/**
 * PageLayout is a page wrapper providing a specified or default header
 * and a footer.
 * Supported props: config, ctx, currentpath, header, theme
 */

import React from 'react';
import Nav from './NavToggle';
import FixedPanel from './FixedPanel';
import Footer from './FooterSimple';
import { setLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';

export default function PageLayout(props) {
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = setLinks(cfg.sections);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };
  const mainstyle = (props.theme ? {className: props.theme} : {});

  return (
    <div id="page">
      <div id="layout-root" className='bg-black-lighter'>
        {props.header && <header>{props.header}</header>}
        {!props.header &&
        <header>
          <Nav toplinks={toplinks}
               sitename={cfg.sitename}
               ctx={props.ctx}
               currentpath={props.currentpath}
               localizerfn={localized} />
          <FixedPanel src={cfg.fixedpanel} btnLabel={cfg.startstudy} visibleLower="sec-0" />
        </header>
        }
        <main {...mainstyle}>{props.children}</main>
        <Footer src={footdata} localizerfn={localized} />
      </div>
    </div>
  );
};
